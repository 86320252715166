import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const LOGIN_URL = "https://a.ntrk2.com/c9456a66-e8fd-4660-bbbf-0ee43ff368f7";
export const REGISTER_URL = "https://a.ntrk2.com/f4129140-d1cf-4c63-9411-96cd71c369b7";

export const LOGIN_URL_MAP = {
  "kbk99play.com": "https://a.ntrk2.com/37f81f30-e47c-435a-b393-aa217f942f2b",
  "kkbk999.com": "https://a.ntrk2.com/37f81f30-e47c-435a-b393-aa217f942f2b",
};

export const REGISTER_URL_MAP = {
  "kbk99play.com": "https://a.ntrk2.com/a48899a3-f0a9-4932-bfc0-59905e71a60f",
  "kkbk999.com": "https://a.ntrk2.com/a48899a3-f0a9-4932-bfc0-59905e71a60f",
};

export const FLOATING_BANNER_URL = "https://kbk9thai.com/cs";

export * from "./codes";
export * from "./context";
