import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import {
  AppDownload,
  Bank,
  BtnLogin,
  BtnRegister,
  HeroChars,
  HeroCoins,
  HeroDragon,
  HeroRoulette,
  HeroSlotreels,
  Logo,
  Providers,
  Text1,
  Text2,
} from "src/assets";
import { FloatingBanner } from "src/main/components";
import { AppContext } from "src/main/constants";
import { createStyles, isMobile } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        window.open(loginUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      } else {
        window.open(registerUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      }
    },
    [loginUrl, registerUrl],
  );

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">KBK99</VisuallyHidden>
      <Stack
        alignItems="center"
        justifyContent="center"
      >
        <img
          alt="text1"
          src={Text1}
          width={320}
        />
        <img
          alt="logo"
          src={Logo}
          width={260}
        />
        <img
          alt="text2"
          src={Text2}
          width={280}
        />

        <Stack
          direction="row"
          spacing={2}
          mt={0.5}
          zIndex={11}
        >
          <a
            href={loginUrl}
            onClick={handleClick("login")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="login button"
              src={BtnLogin}
              height={46}
              style={{ marginTop: 2 }}
            />
          </a>
          <a
            href={registerUrl}
            onClick={handleClick("register")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="register button"
              src={BtnRegister}
              height={46}
            />
          </a>
        </Stack>
      </Stack>

      <Box sx={styles.images}>
        <img
          alt="hero coins"
          src={HeroCoins}
          style={{
            animation: "herocoins 1.2s ease-out 0.6s infinite, fadeCoins 0.6s linear",
            left: 10,
            top: 225,
          }}
        />

        <img
          alt="hero dragon"
          src={HeroDragon}
          style={{
            animation: "herodragon 1.2s ease-out 0.6s infinite, fadeDragon 0.6s linear",
            left: 58,
            top: 20,
          }}
        />

        <img
          alt="hero slotreels"
          src={HeroSlotreels}
          style={{
            width: 140,
            left: 125,
            top: 125,
          }}
        />

        <img
          alt="hero chars"
          src={HeroChars}
          style={{
            animation: "herochars 1.2s ease-out 0.6s infinite, fadeChars 0.6s linear",
            left: 0,
            right: 0,
            margin: "0 auto",
            top: 114,
          }}
        />

        <img
          alt="hero roulette"
          src={HeroRoulette}
          style={{
            left: 2,
            top: 252,
          }}
        />
      </Box>

      <img
        alt="logo"
        src={Logo}
        width={144}
        style={{
          zIndex: 11,
          marginTop: -58,
        }}
      />

      <Stack
        spacing={0.5}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        p={2}
        pt={1}
      >
        <Typography
          fontSize={9}
          lineHeight={1.3}
        >
          ขอต้อนรับทุกท่านสู่ KBK99 เว็บสล็อตที่ดีที่สุดสำหรับผู้ที่หลงใหลในการเล่นเกมสล็อตออนไลน์ ที่ KBK99
          ไม่ว่าคุณจะเป็นผู้เล่นระดับเซียนหรือเพิ่งเริ่มต้น KBK99 ก็ตอบโจทย์ทุกท่าน KBK99 มีธีมเว็บที่สวยงาม น่ารัก
          ฟังก์ชั่นที่เล่นง่าย ไม่มีสะดุด พร้อมโบนัสก้อนโต โปรโมชั่นเด็ด ประสบการณ์ใจฟูรอคุณอยู่ที่นี่ KBK99 จริงๆ
        </Typography>
        <Typography
          fontSize={11}
          lineHeight={1.3}
          fontWeight={600}
        >
          สะดวกมากขึ้นดาวน์โหลดแอพ KBK99 ของเราไว้ที่หน้าจอเลย!
        </Typography>
        <img
          alt="app download"
          src={AppDownload}
          width={220}
        />
        <Typography
          fontSize={9}
          lineHeight={1.3}
        >
          KBK99 ขอเปิดประสบการณ์ใหม่แห่งวงการสล็อตออนไลน์ เรามุ่งมั่นที่จะนำเสนอการเล่นเกม ที่มีรูปแบบการเดิมพันมากมาย
          ทำกำไรง่าย ได้เงินจริง KBK99 รองรับทุกอุปกรณ์ ทั้งคอมและมือถือ KBK99
          เป็นเว็บตรงที่บริการเกมสล็อตจากหลายค่ายชั้นนำ มากกว่า 1,000 เกม รวมเกมที่แตกง่ายแตกบ่อย KBK99
          รวมเกมยอดนิยมที่เหล่านักเดิมพันมองหา เข้าเล่นได้ ตลอด 24 ชั่วโมง ไม่มีสะดุดด้วยระบบที่มีความเสถียร
          ปลอดภัยต่อผู้เล่นทุกท่าน
        </Typography>
        <img
          alt="providers"
          src={Providers}
          width={330}
        />
        <Typography
          fontSize={9}
          lineHeight={1.3}
        >
          KBK99 มีระบบฝากถอนอัตโนมัติ รวดเร็วทันใจ แอดมิน KBK99 ใจดีบริการทุกวัน 24 ชั่วโมง KBK99
          มีโปรโมชั่นสุดพิเศษสำหรับสมาชิกทุกท่าน ทุกอย่างครบจบในที่เดียว KBK99
        </Typography>
        <img
          alt="bank"
          src={Bank}
          width={220}
        />
      </Stack>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    background: `radial-gradient(50% 25% at 50% 0%, #EB81FF 0%, rgba(55, 4, 81, 0.00) 100%, rgba(0, 0, 0, 0.00) 100.01%)`,
    p: 0,
    pt: "16px",

    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    color: "#fff",
  },
  images: {
    marginTop: -3,
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "400px",
    img: {
      position: "absolute",
    },
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
  },
});

export default LandingPage;
